import { FC, ReactNode, PropsWithChildren } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';
import { H2Section } from 'bloko/blocks/header';

import Title from 'src/components/MagritteRedesignComponents/Header';
import useMagritte from 'src/hooks/useMagritte';

interface HeaderProps {
    dataQa?: string;
    subheader: ReactNode | string;
}

const Header: FC<HeaderProps & PropsWithChildren> = ({ children, dataQa, subheader }) => {
    const isMagritte = useMagritte();

    if (!children) {
        return null;
    }

    return (
        <>
            <Title
                Element="h2"
                BlokoElement={H2Section}
                size="medium"
                alignment="left"
                description={subheader}
                dataQaTitle={dataQa}
            >
                {children}
            </Title>
            <VSpacing default={isMagritte ? 24 : 16} />
            {!isMagritte && subheader && <div className="account-login-tile-subheader">{subheader}</div>}
        </>
    );
};

export default Header;
