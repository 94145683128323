import { Fragment, FocusEvent } from 'react';
import { Field } from 'react-final-form';

import { Input as MagritteInput, VSpacing } from '@hh.ru/magritte-ui';
import { FormItem, FormSeparator } from 'bloko/blocks/form';
import InputText from 'bloko/blocks/inputText';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ErrorComponent from 'src/components/AccountLogin/components/ErrorComponent';
import useLoginContext from 'src/components/AccountLogin/hooks/useLoginContext';
import translation from 'src/components/translation';
import { useAutofocusRenderOnce } from 'src/hooks/useAutofocus';
import useMagritte from 'src/hooks/useMagritte';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import useVkAppIntegrationFocus from 'src/hooks/useVkAppIntegrationFocus';
import { LOGIN_FORM } from 'src/hooks/useVkAppIntegrationReady';

const TrlKeys = {
    login: 'login.placeholder',
    employerLogin: 'login.employer.placeholder',
    employerLoginZP: 'login.employer.placeholder.zarplata',
};

interface UsernameFieldProps {
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

const UsernameField: TranslatedComponent<UsernameFieldProps> = ({ trls, onBlur }) => {
    const isZP = useIsZarplataPlatform();
    const { isEmployerForm } = useLoginContext();
    const isRedesign = useMagritte();
    const getAutofocusRef = useAutofocusRenderOnce();
    const InputComponent = isRedesign ? MagritteInput : InputText;
    const additionalProps = isRedesign ? { size: 'large' } : {};
    const FormItemComponent = isRedesign ? Fragment : FormItem;
    let loginPlaceholder: string = isEmployerForm ? trls[TrlKeys.employerLogin] : trls[TrlKeys.login];
    if (isZP) {
        loginPlaceholder = trls[TrlKeys.employerLoginZP];
    }

    const handleFocus = useVkAppIntegrationFocus(LOGIN_FORM);

    return (
        <Field
            name="username"
            type="text"
            onFocus={handleFocus}
            onBlur={onBlur}
            render={({ input, meta, ...otherProps }) => (
                <>
                    <FormItemComponent>
                        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore Удалить вместе с useMagritte после раскатки фейслифта */}
                        <InputComponent
                            {...input}
                            autoCapitalize="off"
                            autoCorrect="off"
                            data-qa="login-input-username"
                            inputMode="email"
                            invalid={meta.invalid && meta.submitFailed}
                            placeholder={loginPlaceholder}
                            spellCheck="false"
                            {...getAutofocusRef(input.value as string)}
                            {...otherProps}
                            {...additionalProps}
                        />
                    </FormItemComponent>
                    {isRedesign && <VSpacing default={12} />}
                    <ErrorComponent meta={meta} />
                    {meta.invalid && meta.submitFailed && <FormSeparator />}
                </>
            )}
        />
    );
};

export default translation(UsernameField);
