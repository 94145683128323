import { useEffect, useState, useRef } from 'react';
import { useField, Field } from 'react-final-form';

import Analytics from '@hh.ru/analytics-js';
import { VSpacing, Title } from '@hh.ru/magritte-ui';
import { FormError } from 'bloko/blocks/form';
import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent, format } from 'bloko/common/trl';

import Captcha from 'src/components/AccountLogin/Form/components/Captcha';
import Header from 'src/components/AccountLogin/components/Title/Header';
import { useLoginContext } from 'src/components/AccountLogin/hooks/useLoginContext';
import Button from 'src/components/MagritteRedesignComponents/Button';
import Input from 'src/components/MagritteRedesignComponents/Input';
import Text from 'src/components/MagritteRedesignComponents/Text';
import CodeError from 'src/components/OTP/CodeError';
import CodeSender from 'src/components/OTP/CodeSender';
import useTimeoutTimer from 'src/components/OTP/useTimeoutTimer';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';

const TrlKeys = {
    submit: 'mobile.native.login.button.caption.short',
    header: 'login.form.otp.header',
    subHeader: 'login.form.otp.subHeader',
    employerPlaceholder: 'otp.employer.placeholder',
    applicantPlaceholder: 'otp.applicant.placeholder',
    noCode: 'login.form.otp.noCode',
    errors: {
        default: 'account.connect.merge_by_code.email.error.unknown',
        CODE_NOT_FOUND: 'account.connect.merge_by_code.email.error.CODE_NOT_FOUND',
        CONFIRM_CODE_BLOCKED: 'account.connect.merge_by_code.email.error.CONFIRM_CODE_BLOCKED',
        CONFIRM_CODE_BLOCKED_PHONE: 'account.connect.merge_by_code.email.error.CONFIRM_CODE_BLOCKED.phone',
        WRONG_CODE: 'account.connect.merge_by_code.email.error.WRONG_CODE',
        CODE_EXPIRED: 'account.connect.merge_by_code.email.error.CODE_EXPIRED',
        ACCOUNT_BLOCKED: 'error.employer.discarded',
        valueMissing: 'form.error.valueMissing',
    },
};

interface VerificationCodeStepProps {
    onNoAccessToEmail: () => void;
}

const getCodeBlockedErrorText = (timer: number, trls: LangTrls) => {
    const errorText = trls[TrlKeys.errors.CONFIRM_CODE_BLOCKED];
    if (timer > 0) {
        return formatToReactComponent(errorText, {
            '{0}': timer,
        });
    }
    return null;
};

const VerificationCodeStep: TranslatedComponent<VerificationCodeStepProps> = ({ trls, onNoAccessToEmail }) => {
    const isMagritte = useMagritte();
    const { isSubmitting, verificationStatus } = useLoginContext();
    const accountType = verificationStatus?.accountType || 'EMPLOYER';
    const [nextConfirmTimestamp, setNextConfirmTimestamp] = useState<number>();
    const timer = useTimeoutTimer(nextConfirmTimestamp || 0);
    const username = useField('username').input.value as string;
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const nextTimestamp =
            verificationStatus?.nextConfirmTime?.['@timestamp'] || verificationStatus?.nextConfirmTime?.timestamp;
        if (nextTimestamp) {
            setNextConfirmTimestamp(nextTimestamp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verificationStatus?.nextConfirmTime]);

    useEffect(() => {
        if (contentRef.current) {
            Analytics.sendHHEventElementShown(contentRef.current, {
                name: 'auth_employer_send_code',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNoAccessToEmail = () => {
        Analytics.sendHHEventButtonClick('one_time_password_not_get');
        onNoAccessToEmail();
    };

    const title = isMagritte ? (
        <Title Element="h1" size="small" description={trls[TrlKeys.subHeader]}>
            {format(trls[TrlKeys.header], {
                '{0}': username,
            })}
        </Title>
    ) : (
        <Header subheader={trls[TrlKeys.subHeader]}>
            {format(trls[TrlKeys.header], {
                '{0}': username,
            })}
        </Header>
    );

    return (
        <div ref={contentRef}>
            {title}
            <VSpacing default={isMagritte ? 24 : 12} />
            <Field
                name="code"
                render={({ input, meta }) => {
                    const errorKey = (meta.error || meta.submitError) as keyof typeof TrlKeys.errors;
                    const translatedError = errorKey && trls[TrlKeys.errors[errorKey]];
                    const errorMessage =
                        verificationStatus?.key === 'CONFIRM_CODE_BLOCKED'
                            ? getCodeBlockedErrorText(timer, trls)
                            : translatedError;
                    return (
                        <>
                            <Input
                                {...input}
                                size="large"
                                autoFocus
                                invalid={meta.invalid && meta.submitFailed && Boolean(errorMessage)}
                                errorMessage={errorMessage}
                                placeholder={
                                    accountType === 'APPLICANT'
                                        ? trls[TrlKeys.applicantPlaceholder]
                                        : trls[TrlKeys.employerPlaceholder]
                                }
                            />
                            {!isMagritte && meta.invalid && meta.submitFailed && Boolean(errorMessage) && (
                                <>
                                    <VSpacing default={8} />
                                    {!verificationStatus || verificationStatus?.key === 'CODE_SEND_OK' ? (
                                        <FormError show>{translatedError}</FormError>
                                    ) : (
                                        <CodeError verification={verificationStatus || null} />
                                    )}
                                </>
                            )}
                        </>
                    );
                }}
            />
            <Captcha />
            <VSpacing default={8} />
            <Text style="secondary">
                <CodeSender
                    url="/shards/otp_generate"
                    login={username}
                    otpType="email"
                    operationType="employer_otp_auth"
                    disableCaptcha
                />
            </Text>
            <VSpacing default={24} />
            <Button
                size="large"
                style="accent"
                mode="primary"
                data-qa="account-login-submit"
                stretched
                type="submit"
                disabled={isSubmitting}
            >
                {trls[TrlKeys.submit]}
            </Button>
            <VSpacing default={12} />
            <Button
                size="large"
                style="accent"
                mode="tertiary"
                data-qa="account-login-no-access-email"
                stretched
                onClick={handleNoAccessToEmail}
            >
                {trls[TrlKeys.noCode]}
            </Button>
        </div>
    );
};

export default translation(VerificationCodeStep);
