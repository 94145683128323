import { FC } from 'react';

import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

interface ContactNumberProps {
    number: string;
}

const ContactNumber: FC<ContactNumberProps> = ({ number }) => {
    const breakpoint = useBreakpoint(Breakpoint.M);
    const isXs = breakpoint === Breakpoint.XS;

    if (isXs) {
        return <a href={`tel:${number}`}>{number}</a>;
    }

    return <>{number}</>;
};

export default ContactNumber;
