import { VSpacing, Title } from '@hh.ru/magritte-ui';
import { ButtonAppearance } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ContactPhones from 'src/components/ContactPhones';
import Messengers from 'src/components/ContactPhones/Messengers';
import Button from 'src/components/MagritteRedesignComponents/Button';
import SupportChatPlate from 'src/components/SupportChatPlate';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

interface NoAccessToEmailInfoStepProps {
    backHandler: () => void;
}

const TrlKeys = {
    info: 'auth.twoFactor.noAccessEmail.info',
    back: 'auth.twoFactor.noAccessEmail.back',
    regionsTel: 'helpPhone.phone.regions',
    mskTel: 'helpPhone.phone.area.1',
    spbTel: 'helpPhone.phone.area.2',
    regions: {
        msk: 'helpPhone.title.msk',
        spb: 'helpPhone.title.spb',
        regions: 'helpPhone.title.regions',
    },
    andRegions: 'employer.auth.city.region',
};

const NoAccessToEmailInfoStep: TranslatedComponent<NoAccessToEmailInfoStepProps> = ({ trls, backHandler }) => {
    const isMagritte = useMagritte();
    const isZP = useIsZarplataPlatform();

    return (
        <>
            <Title Element="h1" size="small">
                {trls[TrlKeys.info]}
            </Title>
            <VSpacing default={24} />

            {!isZP && (
                <>
                    <SupportChatPlate />
                    <VSpacing default={isMagritte ? 12 : 0} />
                    <Messengers />
                    <VSpacing default={isMagritte ? 20 : 0} />
                </>
            )}
            <ContactPhones showHeader={false} formatted />
            <VSpacing default={20} />

            <Button
                size="large"
                style="accent"
                mode="secondary"
                data-qa="account-login-no-access-email-back"
                blokoComponentProps={{
                    appearance: ButtonAppearance.Outlined,
                }}
                stretched
                onClick={backHandler}
            >
                {trls[TrlKeys.back]}
            </Button>
        </>
    );
};

export default translation(NoAccessToEmailInfoStep);
