import { FieldMetaState } from 'react-final-form';

import { FormError } from 'bloko/blocks/form';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    valueMissing: 'form.error.valueMissing',
};

interface ErrorProps {
    meta: FieldMetaState<string>;
}

const ErrorComponent: TranslatedComponent<ErrorProps> = ({ meta, trls }) => {
    if (meta.invalid && meta.submitFailed && TrlKeys[meta.error as keyof typeof TrlKeys]) {
        return <FormError show>{trls[TrlKeys[meta.error as keyof typeof TrlKeys]]}</FormError>;
    }

    return null;
};

export default translation(ErrorComponent);
