import { Fragment } from 'react';
import { Field } from 'react-final-form';

import Analytics from '@hh.ru/analytics-js';
import { PasswordInput, VSpacing } from '@hh.ru/magritte-ui';
import { FormItem } from 'bloko/blocks/form';
import InputPassword from 'bloko/blocks/inputText/inputPassword';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ErrorComponent from 'src/components/AccountLogin/components/ErrorComponent';
import translation from 'src/components/translation';
import { useAutofocusRenderOnce } from 'src/hooks/useAutofocus';
import useMagritte from 'src/hooks/useMagritte';

const TrlKeys = {
    password: 'login.password',
};

interface PasswordFieldProps {
    placeholder?: string;
}

const PasswordField: TranslatedComponent<PasswordFieldProps> = ({ trls, placeholder }) => {
    const isRedesign = useMagritte();
    const getAutofocusRef = useAutofocusRenderOnce();
    const additionalProps = isRedesign ? { size: 'large' } : {};
    const FormItemComponent = isRedesign ? Fragment : FormItem;
    const InputPasswordComponent = isRedesign ? PasswordInput : InputPassword;

    return (
        <Field
            name="password"
            render={({ input, meta }) => (
                <Fragment>
                    <FormItemComponent>
                        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore Удалить вместе с useMagritte после раскатки фейслифта */}
                        <InputPasswordComponent
                            {...input}
                            data-qa="login-input-password"
                            invalid={meta.invalid && meta.submitFailed}
                            {...getAutofocusRef(input.value as string)}
                            placeholder={trls[TrlKeys.password] || placeholder}
                            onToggle={(isPasswordVisible) => {
                                Analytics.sendHHEventButtonClick('login_password_view_toggle', {
                                    isPasswordVisible,
                                });
                            }}
                            {...additionalProps}
                            name={undefined}
                        />
                    </FormItemComponent>
                    {isRedesign && <VSpacing default={8} />}
                    <ErrorComponent meta={meta} />
                </Fragment>
            )}
        />
    );
};

export default translation(PasswordField);
