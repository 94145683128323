import { Button as MagritteButton } from '@hh.ru/magritte-ui';
import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useLoginContext from 'src/components/AccountLogin/hooks/useLoginContext';
import { AuthMethod } from 'src/components/AccountLogin/types';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';

const TrlKeys = {
    submit: 'mobile.native.login.button.caption.short',
    continue: 'Continue',
};

const Submit: TranslatedComponent = ({ trls }) => {
    const { submitProps, authMethod } = useLoginContext();
    const isRedesign = useMagritte();
    const text = authMethod === AuthMethod.ByCode ? trls[TrlKeys.continue] : trls[TrlKeys.submit];

    if (isRedesign) {
        return (
            <MagritteButton {...submitProps} stretched type="submit" mode="primary" style="accent" size="large">
                {text}
            </MagritteButton>
        );
    }

    return (
        <Button {...submitProps} kind={ButtonKind.Primary} stretched type={ButtonType.Submit}>
            {text}
        </Button>
    );
};

export default translation(Submit);
