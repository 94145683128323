import classnames from 'classnames';

import { VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { ButtonKind } from 'bloko/blocks/button';
import BlokoLink from 'bloko/blocks/link';
import { ModalTitle } from 'bloko/blocks/modal';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import Button from 'src/components/MagritteRedesignComponents/Button';
import GridColumn from 'src/components/MagritteRedesignComponents/GridColumn';
import Modal from 'src/components/MagritteRedesignComponents/Modal';
import Text from 'src/components/MagritteRedesignComponents/Text';
import StaticImg from 'src/components/StaticImg';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import styles from './twoFactorPopup.less';

const TWO_FACTOR_ARTICLE = `27435`;

interface TwoFactorPopupProps {
    onClose: () => void;
    redirectUrl: string;
}

const TrlKeys = {
    title: 'account.login.modal.twoFactor.title',
    content: 'account.login.modal.twoFactor.content',
    ok: 'account.login.modal.twoFactor.ok',
    knowMore: 'account.login.modal.twoFactor.knowMore',
};

const TwoFactorPopup: TranslatedComponent<TwoFactorPopupProps> = ({ onClose, redirectUrl, trls }) => {
    const site = useSelector((state) => state.topLevelSite);
    const isWebView = useSelector((state) => state.isWebView);
    const isMagritte = useMagritte();

    return (
        <Modal
            visible
            onClose={onClose}
            blokoModalHeader={
                <>
                    <StaticImg className="two-factor-image" path="/i/hh/2fa.svg" width="201" height="157" alt="" />
                    <ModalTitle Element="h2">{trls[TrlKeys.title]}</ModalTitle>
                </>
            }
            title={trls[TrlKeys.title]}
            content={
                <GridColumn xs="4" s={isMagritte ? '8' : '6'} m={isMagritte ? '8' : '6'} l="6" container>
                    <Text typography="label-1-regular">{format(trls[TrlKeys.content], { '{0}': site })}</Text>
                    <VSpacing default={24} />
                </GridColumn>
            }
            actions={
                <div
                    className={classnames(styles.actionButtonsContainer, {
                        [styles.actionButtonsContainerMagritte]: isMagritte,
                    })}
                >
                    <Button
                        mode="primary"
                        style="accent"
                        size="large"
                        stretched={isMagritte}
                        blokoComponentProps={{
                            kind: ButtonKind.Primary,
                        }}
                        onClick={onClose}
                    >
                        {trls[TrlKeys.ok]}
                    </Button>
                    {isMagritte ? (
                        <Button
                            mode="tertiary"
                            style="accent"
                            size="large"
                            Element={Link as unknown as 'a'}
                            disableVisited
                            stretched
                            to={
                                isWebView
                                    ? `/account/login/two_factor_article?backurl=${encodeURIComponent(redirectUrl)}`
                                    : `/article/${TWO_FACTOR_ARTICLE}`
                            }
                            target="_blank"
                        >
                            {trls[TrlKeys.knowMore]}
                        </Button>
                    ) : (
                        <BlokoLink
                            Element={Link}
                            disableVisited
                            to={
                                isWebView
                                    ? `/account/login/two_factor_article?backurl=${encodeURIComponent(redirectUrl)}`
                                    : `/article/${TWO_FACTOR_ARTICLE}`
                            }
                            target="_blank"
                        >
                            {trls[TrlKeys.knowMore]}
                        </BlokoLink>
                    )}
                </div>
            }
        />
    );
};

export default translation(TwoFactorPopup);
