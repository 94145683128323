import { FC } from 'react';
import classnames from 'classnames';

import { DisplayType } from 'lux/models/displayType';
import NeedHelp from 'src/components/AccountLogin/components/NeedHelp/NeedHelp';
import PasswordRecovery from 'src/components/AccountLogin/components/PasswordRecovery';
import RememberMeButton from 'src/components/AccountLogin/components/RememberMe/RememberMe';
import useLoginContext from 'src/components/AccountLogin/hooks/useLoginContext';
import { useSelector } from 'src/hooks/useSelector';

interface RememberMeProps {
    shouldShowNeedHelp?: boolean;
    shouldShowPasswordRecovery?: boolean;
}

const RememberMe: FC<RememberMeProps> = ({ shouldShowNeedHelp, shouldShowPasswordRecovery = true }) => {
    const { isEmployerForm } = useLoginContext();
    const displayType = useSelector((state) => state.displayType);

    return (
        <div className={`login-remember ${isEmployerForm ? 'login-remember_employer' : 'login-remember_applicant'}`}>
            {displayType === DisplayType.Desktop && <RememberMeButton />}
            <div
                className={classnames('login-remember-footer-actions', {
                    'login-remember-footer-actions_inline': displayType !== DisplayType.Desktop,
                })}
            >
                {shouldShowPasswordRecovery && (
                    <div className="login-remember-footer-actions-link">
                        <PasswordRecovery />
                    </div>
                )}
                {shouldShowNeedHelp && <NeedHelp />}
            </div>
        </div>
    );
};

export default RememberMe;
