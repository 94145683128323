import Link, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';

import NeedHelpModal from 'src/components/AccountLogin/components/NeedHelp/NeedHelpModal/NeedHelpModal';

const TrlKeys = {
    contactUs: 'employer.auth.contactUs.needHelp',
};

const NeedHelp: TranslatedComponent = ({ trls }) => {
    const [isVisible, showModal, hideModal] = useOnOffState(false);
    return (
        <>
            <Link appearance={LinkAppearance.Pseudo} onClick={showModal}>
                {trls[TrlKeys.contactUs]}
            </Link>
            <NeedHelpModal isVisible={isVisible} onClose={hideModal} />
        </>
    );
};

export default translation(NeedHelp);
