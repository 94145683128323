import { ReactNode, FC, ComponentProps } from 'react';

import {
    Action,
    Modal as MagritteModal,
    BottomSheet,
    NavigationBar,
    BottomSheetFooter,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import BlokoModal, { ModalContent, ModalHeader, ModalTitle, ModalFooter } from 'bloko/blocks/modal';

import useMagritte from 'src/hooks/useMagritte';

type MagritteModalProps = ComponentProps<typeof MagritteModal>;
type BlokoModalProps = ComponentProps<typeof BlokoModal>;
type ModalProps = {
    blokoModalHeader?: ReactNode;
    title: string;
    content: ReactNode;
    actions?: ReactNode;
} & MagritteModalProps &
    BlokoModalProps;

const Modal: FC<ModalProps> = ({ content, title, actions, blokoModalHeader, ...props }) => {
    const { isMobile } = useBreakpoint();
    const isMagritte = useMagritte();

    if (!isMagritte) {
        return (
            <BlokoModal visible={props.visible} onClose={props.onClose}>
                <ModalHeader>
                    {blokoModalHeader ? <>{blokoModalHeader}</> : <ModalTitle>{title}</ModalTitle>}
                </ModalHeader>
                <ModalContent>{content}</ModalContent>
                {actions && <ModalFooter>{actions}</ModalFooter>}
            </BlokoModal>
        );
    }

    if (isMobile) {
        return (
            <BottomSheet
                visible={!!props.visible}
                header={<NavigationBar title={title} />}
                footer={actions && <BottomSheetFooter>{actions}</BottomSheetFooter>}
                onClose={props.onClose}
            >
                {content}
            </BottomSheet>
        );
    }

    return (
        <MagritteModal
            actions={<Action mode="secondary" onClick={props.onClose} icon={CrossOutlinedSize24} />}
            titleSize="large"
            title={title}
            rightButtons={actions}
            {...props}
        >
            {content}
        </MagritteModal>
    );
};

export default Modal;
