import { useCallback, useEffect } from 'react';
import { Field } from 'react-final-form';

import { Link, VSpacing } from '@hh.ru/magritte-ui';
import BlokoButton, { ButtonAppearance, ButtonKind, ButtonType } from 'bloko/blocks/button';
import { FormError } from 'bloko/blocks/form';
import { InputType } from 'bloko/blocks/inputText';
import { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { formatSeconds } from 'Utils/Dates';
import { useLoginContext } from 'src/components/AccountLogin/hooks/useLoginContext';
import Button from 'src/components/MagritteRedesignComponents/Button';
import Input from 'src/components/MagritteRedesignComponents/Input';
import Text from 'src/components/MagritteRedesignComponents/Text';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import useTimer from 'src/hooks/useTimer';
import fetcher from 'src/utils/fetcher';

import resendErrorNotification from 'src/components/AccountLogin/steps/TwoFactorStep/AccountLoginResendError';

import styles from './two-factor-step.less';

const TWO_FACTOR_URL = '/account/login/two_factor_event';

declare global {
    interface FetcherPostApi {
        [TWO_FACTOR_URL]: {
            queryParams: void;
            body: { username: string; event: string };
            response: void;
        };
    }
}

interface TwoFactorCodeSenderProps {
    onResendCode: () => Promise<void>;
    onNoAccessToEmail: () => void;
}

const TrlKeys = {
    placeholder: 'auth.twoFactor.code.placeholder',
    timerText: 'auth.twoFactor.timer.text',
    resend: 'auth.twoFactor.timer.resend',
    noAccessToEmail: 'auth.twoFactor.noAccessEmail.button',
    submit: 'mobile.native.login.button.caption.short',
    DEFAULT_ERROR: 'auth.twoFactor.error.unknown',
    CODE_NOT_FOUND: 'auth.twoFactor.error.CODE_NOT_FOUND',
    WRONG_CODE: 'auth.twoFactor.error.WRONG_CODE',
    CODE_EXPIRED: 'auth.twoFactor.error.CODE_EXPIRED',
    valueMissing: 'auth.twoFactor.error.VALUE_MISSING',
};

const TwoFactorCodeSender: TranslatedComponent<TwoFactorCodeSenderProps> = ({
    onResendCode,
    onNoAccessToEmail,
    trls,
}) => {
    const { username = '', isSubmitting, twoFactorTimer, confirmError } = useLoginContext();
    const { addNotification } = useNotification();
    const [timer, setTimer] = useTimer(0);
    const isMagritte = useMagritte();

    const resendCodeHandler = useCallback(async () => {
        fetcher.postFormData(TWO_FACTOR_URL, { username, event: 'resend' }).catch(console.error);
        try {
            await onResendCode();
        } catch (err) {
            addNotification(resendErrorNotification);
        }
    }, [addNotification, onResendCode, username]);

    useEffect(() => {
        void fetcher.postFormData(TWO_FACTOR_URL, { username, event: 'shown' });
    }, [username]);

    useEffect(() => {
        if (!twoFactorTimer) {
            return;
        }

        setTimer(twoFactorTimer);
    }, [setTimer, twoFactorTimer]);

    const resend = isMagritte ? (
        <Link onClick={resendCodeHandler}>{trls[TrlKeys.resend]}</Link>
    ) : (
        <BlokoButton
            kind={ButtonKind.Primary}
            type={ButtonType.Button}
            appearance={ButtonAppearance.Outlined}
            onClick={resendCodeHandler}
            disabled={isSubmitting}
            stretched
        >
            {trls[TrlKeys.resend]}
        </BlokoButton>
    );

    const renderTimer = () => {
        return (
            <>
                {timer > 0 ? (
                    <Text
                        style="tertiary"
                        blokoComponentProps={{
                            importance: TextImportance.Tertiary,
                        }}
                    >
                        <span suppressHydrationWarning>
                            {format(trls[TrlKeys.timerText], { '{0}': formatSeconds(timer) })}
                        </span>
                    </Text>
                ) : (
                    resend
                )}
            </>
        );
    };

    return (
        <>
            <div className={isMagritte ? '' : styles.fieldWrapper}>
                <Field
                    name="code2fa"
                    type="number"
                    render={({ input, meta }) => {
                        const isInvalid = meta.invalid && meta.submitFailed;
                        const errorTrlKey = (isInvalid ? meta.error : confirmError) as keyof typeof TrlKeys;
                        const errorTranslated = trls[TrlKeys[errorTrlKey] || TrlKeys.DEFAULT_ERROR];
                        return (
                            <div className={isMagritte ? '' : styles.code}>
                                <Input
                                    {...input}
                                    size="large"
                                    type={InputType.Text}
                                    name="code2fa"
                                    placeholder={trls[TrlKeys.placeholder]}
                                    data-qa="login-form-code-2fa"
                                    invalid={!!confirmError || isInvalid}
                                />
                                <VSpacing default={isMagritte ? 12 : 10} />
                                <FormError show={!!confirmError || isInvalid}>{errorTranslated}</FormError>
                            </div>
                        );
                    }}
                />
                <div className={isMagritte ? '' : styles.timer}>{renderTimer()}</div>
            </div>
            <VSpacing default={isMagritte ? 24 : 32} />
            <Button
                size="large"
                style="accent"
                mode="primary"
                blokoComponentProps={{
                    kind: ButtonKind.Primary,
                }}
                data-qa="account-login-submit"
                stretched
                type={ButtonType.Submit}
                disabled={isSubmitting}
            >
                {trls[TrlKeys.submit]}
            </Button>
            <VSpacing default={isMagritte ? 12 : 16} />
            <Button
                size="large"
                style="accent"
                mode="tertiary"
                blokoComponentProps={{
                    appearance: ButtonAppearance.Outlined,
                }}
                data-qa="account-login-no-access-email"
                stretched
                onClick={onNoAccessToEmail}
            >
                {trls[TrlKeys.noAccessToEmail]}
            </Button>
        </>
    );
};

export default translation(TwoFactorCodeSender);
