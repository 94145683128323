/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { push } from 'connected-react-router';

import Analytics from '@hh.ru/analytics-js';
import { HhcaptchaType, RecaptchaType, useCaptcha } from '@hh.ru/hhcaptcha';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { OtpRequest, Verification } from 'lux/models/applicant/auth';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';
import { LoginFormValues, TwoFactorCheckStatus, AuthMethod } from 'src/components/AccountLogin/types';
import { useNotification } from 'src/components/Notifications/Provider';
import useIsClient from 'src/hooks/useIsClient';
import { useSelector } from 'src/hooks/useSelector';
import formatAnalyticErrors from 'src/utils/analytics/formatAnalyticErrors';
import fetcher from 'src/utils/fetcher';

import useAuthMethodSwitch from 'src/components/AccountLogin/hooks/useAuthMethodSwitch';
import useLoginSteps, { LoginSteps } from 'src/components/AccountLogin/hooks/useLoginStep';

const otpAction = makeSetStoreField('otp');
const setLoginError = makeSetStoreField('loginError');

const loginUrl = '/account/login' as const;
const loginByCodeUrl = '/account/login/by_code' as const;
const otpUrl = '/account/otp_generate' as const;

interface ResponseLoginError {
    code: string;
    trl: string;
}

declare global {
    interface FetcherPostApi {
        [loginUrl]: {
            queryParams: void;
            body: FormData;
            response: {
                recaptcha: RecaptchaType;
                hhcaptcha?: HhcaptchaType;
                loginError?: ResponseLoginError;
                show2FAPopup?: boolean;
                redirectUrl?: string;
                waitBeforeSendTime?: number;
                reason?: string;
            };
        };
        [loginByCodeUrl]: {
            queryParams: void;
            body: FormData;
            response: {
                recaptcha: RecaptchaType;
                hhcaptcha?: HhcaptchaType;
                success?: boolean;
                verification?: Verification;
                backurl?: string;
            };
        };
    }
}

type LoginError = RecaptchaType | HhcaptchaType | ResponseLoginError | string | string[] | null;

interface Props {
    onSubmitResult?: ({ values }: { values?: LoginFormValues }) => void;
    onStepChange?: (step: LoginSteps) => void;
    initialValues?: Partial<LoginFormValues>;
    initialStep?: LoginSteps;
    initialAuthMethod?: AuthMethod;
    isEmployerForm?: boolean;
    addExternalMethod?: boolean;
}

const getFormValues = (formData: FormData): LoginFormValues => {
    return Object.fromEntries(formData.entries()) as unknown as LoginFormValues;
};

const isBot = (captcha?: HhcaptchaType | RecaptchaType) => {
    if (captcha?.isBot) {
        return captcha;
    }
    return null;
};

const useLoginForm = (props: Props = { isEmployerForm: false }) => {
    const {
        onSubmitResult,
        onStepChange,
        isEmployerForm,
        initialValues: externalInitialValues,
        initialAuthMethod = AuthMethod.ByPassword,
        initialStep,
        ...restProps
    } = props;
    const dispatch = useDispatch();
    const formRef = useRef<HTMLFormElement>(null);
    const { step, setStep: _setStep, onPreviousStep } = useLoginSteps(initialStep);
    const { authMethod, onByPassword, onByCode } = useAuthMethodSwitch(initialAuthMethod);
    const [twoFactorPopupUrl, setTwoFactorPopupUrl] = useState<string>();
    const [twoFactorTimer, setTwoFactorTimer] = useState<number>();
    const [confirmError, setConfirmError] = useState<string>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [verificationType, setVerificationType] = useState<TwoFactorCheckStatus | null>(null);
    const [verificationStatus, setVerificationStatus] = useState<Verification | null>(null);
    const isForbidden = useSelector((state) => state.forbidden);
    const location = useSelector((state) => state.router.location);
    const vacancyId = useSelector(({ postponedActions }) => postponedActions?.vacancy?.vacancyId);
    const authParams = useSelector((state) => state.authUrl);
    const backurl = useSelector((state) => state.authUrl?.backurl || state.otp?.backurl);
    const captcha = useCaptcha();
    const { addNotification } = useNotification();
    const disableSubmit = !!twoFactorPopupUrl || isSubmitting;
    const isClient = useIsClient();

    const submitProps = {
        'data-qa': 'account-login-submit',
        disabled: !isClient || disableSubmit,
    };

    useEffect(() => {
        return () => {
            dispatch(setLoginError(null));
        };
    }, [dispatch]);

    const setStep = useCallback(
        (newStep: LoginSteps) => {
            if (newStep === step) {
                return;
            }
            _setStep(newStep);
            onStepChange?.(newStep);
        },
        [_setStep, onStepChange, step]
    );

    const redirect = useCallback(
        (redirectUrl: string) => {
            if (location.pathname + location.search === redirectUrl) {
                window.location.reload();
            } else if (isForbidden) {
                window.location.assign(redirectUrl);
            } else {
                dispatch(push(redirectUrl));
            }
        },
        [dispatch, isForbidden, location.pathname, location.search]
    );

    const sendSubmitResultAnalyticsEvent = useCallback(
        (errors?: LoginError) => {
            Analytics.sendHHEvent('form_submit', {
                formName: isEmployerForm ? `employer_authorization` : 'applicant_authorization',
                type: authMethod,
                ...(errors ? { errors: formatAnalyticErrors('login', errors) } : {}),
                ...(vacancyId ? { vacancyId } : {}),
            });
        },
        [authMethod, isEmployerForm, vacancyId]
    );

    const onLoginOtp = useCallback(async () => {
        let response;
        setIsSubmitting(true);
        const formData = new FormData(formRef.current || undefined);
        const values = getFormValues(formData);

        try {
            response = await fetcher.postFormData(loginByCodeUrl, formData);
        } catch (e) {
            setIsSubmitting(false);
            const error = e as AxiosError<{
                error?: { key: string };
            }>;
            const errorCode = error?.response?.data?.error?.key;
            sendSubmitResultAnalyticsEvent(errorCode);
            onSubmitResult?.({ values });
            if (errorCode === 'UNEXPECTED_USER_TYPE') {
                onByPassword();
                _setStep(LoginSteps.Login);
                return undefined;
            }
            if (errorCode === 'ACCOUNT_NOT_FOUND') {
                dispatch(push('/auth/employer?noAccountFoundError=true'));
                return undefined;
            }
            if (errorCode === 'ACCOUNT_BLOCKED') {
                setVerificationStatus(null);
                return { code: errorCode };
            }
            defaultRequestErrorHandler(error, addNotification);
            return undefined;
        }

        const errorCode = response?.data?.success ? null : response?.data?.verification?.key;
        setIsSubmitting(false);

        if (response?.data?.success) {
            sendSubmitResultAnalyticsEvent();
            onSubmitResult?.({ values });
            window.location.assign(response.data.backurl || backurl || '/');
            return undefined;
        }

        if (response?.data?.verification) {
            setVerificationStatus(response?.data?.verification);
        }
        sendSubmitResultAnalyticsEvent(errorCode);
        onSubmitResult?.({ values });
        return { code: errorCode };
    }, [_setStep, addNotification, backurl, dispatch, onByPassword, onSubmitResult, sendSubmitResultAnalyticsEvent]);

    const onSendOtp = useCallback(async () => {
        setIsSubmitting(true);
        let data;
        const formData = new FormData(formRef.current || undefined);
        captcha.addCaptchaParams(formData);
        formData.append('login', formData.get('username') || '');
        formData.append('otpType', 'email');
        formData.append('operationType', formData.get('operationType') || 'employer_otp_auth');
        const values = getFormValues(formData);
        try {
            const response = await fetcher.post(otpUrl, formData as unknown as OtpRequest['body']);
            data = response.data;
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
            setIsSubmitting(false);
            return;
        }

        setIsSubmitting(false);
        captcha.updateCaptcha(data);

        if (data.otp) {
            dispatch(otpAction(data.otp));
        }

        if (data.key === 'BAD_LOGIN') {
            dispatch(setLoginError({ code: data.key }));
        }

        if (data.redirectURL) {
            redirect(data.redirectURL);
            return;
        }

        if (data.success || data.key === 'CODE_SEND_BLOCKED') {
            onSubmitResult?.({ values });
            dispatch(setLoginError(null));
            setVerificationStatus(data as Verification);
            setStep(LoginSteps.OtpVerification);
        }
    }, [addNotification, captcha, dispatch, onSubmitResult, redirect, setStep]);

    const onLoginByPassword = useCallback(
        async (deleteCode2fa = false) => {
            setIsSubmitting(true);
            const formData = new FormData(formRef.current || undefined);
            const url = authParams?.['login-url'] as typeof loginUrl;
            deleteCode2fa && formData.append('resend_code_2fa', 'true');
            captcha.addCaptchaParams(formData);
            const values = getFormValues(formData);

            let response;
            try {
                response = await fetcher.post(url, formData);
            } catch (error) {
                defaultRequestErrorHandler(error, addNotification);
                sendSubmitResultAnalyticsEvent(String((error as AxiosError)?.response?.status || 'network_error'));
                onSubmitResult?.({ values });
                return;
            }

            const { loginError, recaptcha, hhcaptcha, redirectUrl, show2FAPopup } = response.data || {};
            const err = loginError || isBot(hhcaptcha) || isBot(recaptcha) || null;
            sendSubmitResultAnalyticsEvent(err);
            onSubmitResult?.({ values });

            if (redirectUrl) {
                if (show2FAPopup) {
                    setTwoFactorPopupUrl(redirectUrl);
                    return;
                }

                redirect(redirectUrl);
                return;
            }
            if (loginError) {
                dispatch(setLoginError(loginError));
                const errorCode = loginError?.code;
                if (
                    Object.values(TwoFactorCheckStatus).includes(errorCode as TwoFactorCheckStatus) &&
                    step !== LoginSteps.NoAccessToEmailStep
                ) {
                    setVerificationType(errorCode as TwoFactorCheckStatus);
                    setStep(LoginSteps.TwoFactorStep);
                    setTwoFactorTimer(response.data.waitBeforeSendTime);
                    setConfirmError(response.data.reason);
                } else if (errorCode === 'mailru-external-only-mismatch') {
                    setStep(LoginSteps.MailruError);
                } else if (errorCode === '2fa_check_account_blocked') {
                    setStep(LoginSteps.AccountBlocked);
                } else {
                    setStep(LoginSteps.Login);
                }
            }

            captcha.updateCaptcha(response.data);
            setIsSubmitting(false);
        },
        [
            addNotification,
            authParams,
            captcha,
            dispatch,
            onSubmitResult,
            redirect,
            sendSubmitResultAnalyticsEvent,
            setStep,
            step,
        ]
    );

    const onSubmit = useCallback(
        async (deleteCode2fa = false) => {
            if (step === LoginSteps.Login && authMethod === AuthMethod.ByCode) {
                return onSendOtp();
            }
            if (step === LoginSteps.OtpVerification) {
                return onLoginOtp();
            }
            return onLoginByPassword(deleteCode2fa);
        },
        [authMethod, onLoginByPassword, onLoginOtp, onSendOtp, step]
    );

    const initialUsername = authParams?.['login-field-value-entered'] || authParams?.['login-field-value'];
    const initialValues = {
        username: initialUsername,
        ...(authParams?.['remember-field-name']
            ? {
                  [authParams['remember-field-name']]: authParams?.['remember-field-value'],
              }
            : {}),
        ...externalInitialValues,
    };

    const validate = useCallback(
        (values: LoginFormValues) => {
            return {
                username: !values.username ? 'valueMissing' : undefined,
                password: authMethod === AuthMethod.ByPassword && !values.password ? 'valueMissing' : undefined,
                code: step === LoginSteps.OtpVerification && !values.code ? 'valueMissing' : undefined,
                code2fa: step === LoginSteps.TwoFactorStep && !values.code2fa ? 'valueMissing' : undefined,
            };
        },
        [authMethod, step]
    );

    const handleAuthMethodSwitch = useCallback(
        (switchMethod: () => void) => () => {
            dispatch(setLoginError(null));
            setConfirmError(undefined);
            switchMethod();
        },
        [dispatch]
    );

    return {
        onSubmit,
        onSendOtp,
        twoFactorPopupUrl,
        twoFactorTimer,
        confirmError,
        onResetConfirmError: () => setConfirmError(undefined),
        authMethod,
        onByPassword: handleAuthMethodSwitch(onByPassword),
        onByCode: handleAuthMethodSwitch(onByCode),
        onPreviousStep,
        isSubmitting,
        verificationType,
        verificationStatus,
        redirect,
        formRef,
        validate,
        initialValues,
        isEmployerForm,
        step,
        setStep,
        submitProps,
        ...restProps,
    };
};

export default useLoginForm;
