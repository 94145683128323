import useExperiment from 'src/hooks/useExperiment';
import useNote from 'src/hooks/useNote';
import { useIsHhru } from 'src/hooks/useSites';

const Notes = {
    employerOtpExperiment: 'employerOtpExperimentPortfolio30467',
};

const useEmployerOtpExperiment = (isEmployerRegistration = false): boolean => {
    const isHHRu = useIsHhru();
    const [note] = useNote<boolean>(Notes.employerOtpExperiment);
    const isExperimentEnabled = useExperiment('employer_auth_otp_exp', isHHRu && isEmployerRegistration);

    if (!isHHRu) {
        return false;
    }

    if (!isEmployerRegistration) {
        return Boolean(note);
    }

    return isExperimentEnabled;
};

export default useEmployerOtpExperiment;
