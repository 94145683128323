import { useContext } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { ChatikContext } from '@hh.ru/chatik-integration';
import Button, { ButtonIconPosition } from 'bloko/blocks/button';
import {
    TelegramScaleSmallAppearanceColor,
    ViberScaleSmallAppearanceColor,
    WhatsappScaleSmallAppearanceColor,
} from 'bloko/blocks/icon';
import Modal, { ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import Text, { TextSize, TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { DeepLink } from 'Utils/DeepLink';
import { getMessengerLinks, CHAT, PHONE, MessengersTypes } from 'Utils/DeepLinkHelper';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';

import ContactNumber from 'src/components/AccountLogin/components/NeedHelp/NeedHelpModal/ContactNumber';

import styles from './need-help-modal.less';

const TrlKeys = {
    title: 'employer.auth.needHelpModal.title',
    chatTitle: 'employer.auth.needHelpModal.chat.title',
    chatSite: 'employer.auth.needHelpModal.chat.site',
    TELEGRAM: 'employer.auth.needHelpModal.chat.telegram',
    WHATSAPP: 'employer.auth.needHelpModal.chat.whatsApp',
    VIBER: 'employer.auth.needHelpModal.chat.viber',
    phonesTitle: 'employer.auth.needHelpModal.phones.title',
    phonesMsk: 'employer.auth.needHelpModal.phones.msk',
    phonesSpb: 'employer.auth.needHelpModal.phones.spb',
    phonesRegions: 'employer.auth.needHelpModal.phones.regions',
    phonesMskNumber: 'employer.auth.needHelpModal.phones.msk.number',
    phonesSpbNumber: 'employer.auth.needHelpModal.phones.spb.number',
    phonesRegionsNumber: 'employer.auth.needHelpModal.phones.regions.number',
};

interface NeedHelpModalProps {
    onClose: () => void;
    isVisible: boolean;
}

const MessengerIconMap = {
    TELEGRAM: TelegramScaleSmallAppearanceColor,
    VIBER: ViberScaleSmallAppearanceColor,
    WHATSAPP: WhatsappScaleSmallAppearanceColor,
} as const;

const NeedHelpModal: TranslatedComponent<NeedHelpModalProps> = ({ trls, onClose, isVisible }) => {
    const messengers = useSelector(({ messengers }) => messengers);
    const displayType = useSelector(({ displayType }) => displayType);
    const openChatik = useContext(ChatikContext)?.openChatik;
    const isRabotBy = useIsRabotaBy();

    const handleClickChatSite = () => {
        Analytics.sendHHEventButtonClick('support_chat');
        openChatik?.({ chatId: 'support', view: 'widget', hhtmFromLabel: 'account_login_need_help' });
        onClose();
    };

    return (
        <Modal visible={isVisible} onClose={onClose}>
            <ModalHeader>
                <ModalTitle>{trls[TrlKeys.title]}</ModalTitle>
            </ModalHeader>
            <div>
                <Text size={TextSize.Medium} importance={TextImportance.Secondary}>
                    {trls[TrlKeys.chatTitle]}
                </Text>
                <VSpacing base={3} />
                <div className={styles.needHelpModalActions}>
                    <Button stretched onClick={handleClickChatSite}>
                        {trls[TrlKeys.chatSite]}
                    </Button>
                    {messengers.map((messenger) => {
                        const messengerType = messenger.messengerType.toLowerCase() as MessengersTypes;

                        const phone = `${messenger.country}${messenger.city}${messenger.number}`;
                        const links = getMessengerLinks({
                            phone,
                            accountName: messenger.accountName || '',
                            displayType,
                            viberType: isRabotBy ? PHONE : CHAT,
                        });
                        const handleClickMessengerChat = () => {
                            Analytics.sendHHEventButtonClick('helpphone_messenger', {
                                goal: messenger.messengerType,
                            });

                            const deepLink = new DeepLink({
                                fallbackUrl: links[messengerType].shallow,
                                androidUrl: links[messengerType].android,
                                iosUrl: links[messengerType].ios,
                            });
                            deepLink.open(links[messengerType].deep);
                        };

                        const MessengerIcon = MessengerIconMap[messenger.messengerType];

                        return (
                            <Button
                                stretched
                                key={messengerType}
                                onClick={handleClickMessengerChat}
                                iconPosition={ButtonIconPosition.Right}
                                icon={<MessengerIcon />}
                            >
                                {trls[TrlKeys[messenger.messengerType]]}
                            </Button>
                        );
                    })}
                </div>
                <VSpacing base={8} xs={6} />
                <Text size={TextSize.Medium} importance={TextImportance.Secondary}>
                    {trls[TrlKeys.phonesTitle]}
                </Text>
                <VSpacing base={3} />
                <div className={styles.needHelpModalContacts}>
                    <div className={styles.needHelpModalContactsItem}>
                        <Text size={TextSize.Medium}>{trls[TrlKeys.phonesMsk]}</Text>
                        <Text size={TextSize.Medium} strong>
                            <ContactNumber number={trls[TrlKeys.phonesMskNumber]} />
                        </Text>
                    </div>
                    <div className={styles.needHelpModalContactsItem}>
                        <Text size={TextSize.Medium}>{trls[TrlKeys.phonesSpb]}</Text>
                        <Text size={TextSize.Medium} strong>
                            <ContactNumber number={trls[TrlKeys.phonesSpbNumber]} />
                        </Text>
                    </div>
                    <div className={styles.needHelpModalContactsItem}>
                        <Text size={TextSize.Medium}>{trls[TrlKeys.phonesRegions]}</Text>
                        <Text size={TextSize.Medium} strong>
                            <ContactNumber number={trls[TrlKeys.phonesRegionsNumber]} />
                        </Text>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default translation(NeedHelpModal);
