import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useLoginContext from 'src/components/AccountLogin/hooks/useLoginContext';
import { AuthMethod } from 'src/components/AccountLogin/types';
import Link from 'src/components/MagritteRedesignComponents/Link';
import translation from 'src/components/translation';

const TrlKeys = {
    actionByCode: 'login.action.by.code',
    actionByPassword: 'login.action.by.password',
};

const AuthMethodSwitcher: TranslatedComponent = ({ trls }) => {
    const { onByCode, onByPassword, authMethod } = useLoginContext();
    const handleClick = () => {
        if (authMethod === AuthMethod.ByCode) {
            onByPassword?.();
            return;
        }
        onByCode?.();
    };
    return (
        <Link data-qa={`expand-login-${authMethod}`} typography="label-2-regular" onClick={handleClick}>
            {authMethod === AuthMethod.ByCode ? trls[TrlKeys.actionByPassword] : trls[TrlKeys.actionByCode]}
        </Link>
    );
};

export default translation(AuthMethodSwitcher);
