import { Field, FieldInputProps } from 'react-final-form';

import { Text, Checkbox as MagritteCheckbox } from '@hh.ru/magritte-ui';
import Checkbox from 'bloko/blocks/checkbox';
import HSpacing from 'bloko/blocks/hSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import styles from './remember-me.less';

const TrlKeys = {
    remember: 'account.login.remember',
};

const RememberMe: TranslatedComponent = ({ trls }) => {
    const authParams = useSelector((state) => state.authUrl);
    const isRedesign = useMagritte();

    const renderCheckbox = (input: FieldInputProps<string, HTMLElement>) => {
        if (isRedesign) {
            return (
                <label className={styles.checkboxContainer}>
                    <MagritteCheckbox
                        {...input}
                        dataQaCheckbox="account-login-remember"
                        name={undefined}
                        aria-label={trls[TrlKeys.remember]}
                    />
                    <HSpacing base={1} />
                    <Text typography="label-2-regular">{trls[TrlKeys.remember]}</Text>
                </label>
            );
        }

        return (
            <Checkbox {...input} data-qa="account-login-remember" name={undefined}>
                {trls[TrlKeys.remember]}
            </Checkbox>
        );
    };

    if (!authParams) {
        return null;
    }

    return (
        <Field
            type="checkbox"
            name={authParams['remember-field-name']}
            render={({ input }) => renderCheckbox(input as FieldInputProps<string, HTMLElement>)}
        />
    );
};

export default translation(RememberMe);
