import { Field } from 'react-final-form';

import { VSpacing } from '@hh.ru/magritte-ui';
import { ButtonKind, ButtonType } from 'bloko/blocks/button';
import Checkbox from 'bloko/blocks/checkbox';
import { FormError } from 'bloko/blocks/form';
import { InputType } from 'bloko/blocks/inputText';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useLoginContext } from 'src/components/AccountLogin/hooks/useLoginContext';
import Hhcaptcha from 'src/components/Hhcaptcha';
import Button from 'src/components/MagritteRedesignComponents/Button';
import Input from 'src/components/MagritteRedesignComponents/Input';
import Recaptcha from 'src/components/Recaptcha';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';

import styles from './two-factor-totp-step.less';

const CHECK_2FA_TOTP_WRONG_CODE_STATE = '2fa_totp_check_wrong_code';

const TrlKeys = {
    placeholder: 'auth.twoFactor.code.placeholder',
    submit: 'mobile.native.login.button.caption.short',
    defaultError: 'auth.twoFactor.error.unknown',
    wrongCodeError: 'auth.twoFactor.error.WRONG_CODE',
    trustThisSource: 'auth.twoFactor.totp.trustThisSource',
};

const TwoFactorTotp: TranslatedComponent = ({ verificationType, trls }) => {
    const { isSubmitting, confirmError } = useLoginContext();
    const isMagritte = useMagritte();

    const getErrorText = (verificationType: unknown) => {
        return verificationType === CHECK_2FA_TOTP_WRONG_CODE_STATE
            ? trls[TrlKeys.wrongCodeError]
            : trls[TrlKeys.defaultError];
    };

    if (isMagritte) {
        return (
            <>
                <VSpacing default={0} />
                <div className={''}>
                    <Field
                        name="code2fa"
                        type="number"
                        render={({ input, meta }) => (
                            <div className={''}>
                                <Input
                                    {...input}
                                    name="code2fa"
                                    size="large"
                                    type={InputType.Text}
                                    placeholder={trls[TrlKeys.placeholder]}
                                    data-qa="login-form-code-2fa"
                                    invalid={
                                        !!confirmError ||
                                        (meta.invalid && meta.submitFailed) ||
                                        verificationType === CHECK_2FA_TOTP_WRONG_CODE_STATE
                                    }
                                    autoFocus
                                />
                                <VSpacing default={12} />
                                <FormError
                                    show={
                                        !!confirmError ||
                                        (meta.invalid && meta.submitFailed) ||
                                        verificationType === CHECK_2FA_TOTP_WRONG_CODE_STATE
                                    }
                                >
                                    {getErrorText(verificationType)}
                                </FormError>
                            </div>
                        )}
                    />
                </div>
                <Checkbox data-qa="totp-trust-this-source" name="trust_this_source">
                    {trls[TrlKeys.trustThisSource]}
                </Checkbox>
                <Recaptcha />
                <Hhcaptcha />
                <VSpacing default={24} />
                <Button
                    size="large"
                    style="accent"
                    mode="primary"
                    data-qa="account-login-submit"
                    blokoComponentProps={{
                        kind: ButtonKind.Primary,
                        type: ButtonType.Submit,
                    }}
                    stretched
                    type="submit"
                    disabled={isSubmitting}
                >
                    {trls[TrlKeys.submit]}
                </Button>
            </>
        );
    }

    return (
        <>
            <VSpacing default={32} />
            <div className={styles.fieldWrapper}>
                <Field
                    name="code2fa"
                    type="number"
                    render={({ input, meta }) => {
                        return (
                            <div className={styles.code}>
                                <Input
                                    {...input}
                                    name="code2fa"
                                    type={InputType.Text}
                                    placeholder={trls[TrlKeys.placeholder]}
                                    data-qa="login-form-code-2fa"
                                    invalid={
                                        !!confirmError ||
                                        (meta.invalid && meta.submitFailed) ||
                                        verificationType === CHECK_2FA_TOTP_WRONG_CODE_STATE
                                    }
                                    autoFocus
                                />
                                <VSpacing default={10} />
                                <FormError
                                    show={
                                        !!confirmError ||
                                        (meta.invalid && meta.submitFailed) ||
                                        verificationType === CHECK_2FA_TOTP_WRONG_CODE_STATE
                                    }
                                >
                                    {getErrorText(verificationType)}
                                </FormError>
                            </div>
                        );
                    }}
                />
            </div>
            <Checkbox data-qa="totp-trust-this-source" name="trust_this_source">
                {trls[TrlKeys.trustThisSource]}
            </Checkbox>
            <Recaptcha />
            <Hhcaptcha />
            <VSpacing default={32} />
            <Button
                size="large"
                style="accent"
                mode="primary"
                data-qa="account-login-submit"
                blokoComponentProps={{
                    kind: ButtonKind.Primary,
                    type: ButtonType.Submit,
                }}
                stretched
                type="submit"
                disabled={isSubmitting}
            >
                {trls[TrlKeys.submit]}
            </Button>
        </>
    );
};

export default translation(TwoFactorTotp);
